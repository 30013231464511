// src/components/Card.js
import React from "react";
import "../../src/style.css";
const Card = ({ image, title, subtitle }) => {
  return (
    
    <div className="custom-card">
      <div className="row">
        <div className="col-6 d-flex justify-content-center align-items-center">
          <span className="card-title">{title}</span>
        </div>
        <div className="col-6 d-flex justify-content-center align-items-center">
          <img
            src={image}
            className="card-img-top text-center p-2"
            alt={title}
            style={{width: "120%"}}
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
